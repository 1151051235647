<template>
  <b-container>
    <b-breadcrumb>
      <b-breadcrumb-item href="#home">
        <router-link :to="{ name: 'root' }">Home </router-link>
      </b-breadcrumb-item>

      <b-breadcrumb-item>
        Register Device
      </b-breadcrumb-item>
    </b-breadcrumb>
    <div class="b-main-content">
      <b-card>
        <template #header>
          <h3 class="mb-0">
            Adding a device
          </h3>
          <p>
            <br />This is the default device registration handler, for
            development and testing. You can change this for production usage.
            <a
              href="https://developer.blecon.net/learn-blecon/blecon-networks/registering-devices"
              target="_new"
              >Learn more about adding devices.</a
            >
          </p>
        </template>
        <b-card-text>
          <p class="text-center">
            <b>Account</b>
            <i>
              {{ $store.state.accountName }} ({{ $store.state.accountid }})</i
            >
          </p>
          <p></p>
          <p class="text-center">
            Device <b>{{ this.$route.query.device_id }}</b> ⭢ Network
            <b>{{ this.$route.query.network_id }}</b>
          </p>

          <b-button
            class="float-right"
            variant="success"
            size="md"
            @click="registerDevice"
            >Confirm registration</b-button
          >
        </b-card-text>
      </b-card>

      <div></div>
    </div>
  </b-container>
</template>

<script>
import router from "@/router";
import store from "@/store";

export default {
  data() {
    return {
      postData: {}
    };
  },

  methods: {
    async registerDevice() {
      // register a device from a GET request

      //check to see if it's registered already
      this.$axios.defaults.headers.common["authorization"] =
        "bearer " + (await this.$auth.getTokenSilently());
      this.$axios
        .get(
          "/v1/accounts/" +
            this.$store.state.accountid +
            "/devices/" +
            this.$route.query.device_id
        )
        .then(response => {
          this.$notify({
            title: "This device is already registered to your account.",
            type: "success"
          });
          router.push({
            name: "viewdevice",
            params: {
              account: this.$store.state.accountid,
              devId: this.$route.query.device_id
            }
          });
          return {};
        })
        .catch(err => {
          // 404 means we should try and register

          this.$axios
            .post("/v1/accounts/" + this.$store.state.accountid + "/devices", {
              device_id: this.$route.query.device_id,
              network_id: this.$route.query.network_id,
              model: this.$route.query.device_model
            })
            .then(response => {
              this.$notify({
                title: "Device Registered",
                type: "success"
              });

              router.push({
                name: "viewdevice",
                params: {
                  account: this.$store.state.accountid,
                  devId: this.$route.query.device_id
                }
              });
            })

            .catch(err => {
              //malformed request
              this.$notify({
                title:
                  "Error while adding device " +
                  (err.response.data.message || ""),
                type: "error"
              });

              router.push({
                name: "root",
                params: { account: this.$store.state.accountid }
              });
            });
        });
    }
  },
  created() {
    //this.registerDevice();
  }
};
</script>
