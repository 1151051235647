<template>
  <b-nav-item-dropdown
    v-if="!$store.state.initialLoad && $store.state.accountid"
  >
    <template slot="button-content">
      <i class="bx bx-sitemap"></i> {{ $store.state.accountName }}
    </template>

    <b-dropdown-group
      id="dropdown-group-1"
      :header="'Selected account: ' + $store.state.accountName"
    >
      <b-dropdown-item
        :to="{
          name: 'account_members',
          params: { account: $store.state.accountid }
        }"
      >
        Account Information and Settings
      </b-dropdown-item>
      <b-dropdown-item
        v-if="$store.state.gfwEnabled"
        :to="{
          name: 'global_forwarders',
          params: { account: $store.state.accountid }
        }"
      >
        Global Forwarders
      </b-dropdown-item>
    </b-dropdown-group>

    <b-dropdown-group
      id="dropdown-group-1"
      header="Switch accounts"
      v-if="$store.state.accountList.length > 1"
    >
      <b-dropdown-item
        v-for="a in $store.state.accountList"
        :key="a.account_id"
        v-on:click="switchAccount(a.account_id)"
        :disabled="a.account_id == $store.state.accountid"
        :hidden="a.account_id == $store.state.accountid"
      >
        {{ a.name }}
      </b-dropdown-item>
    </b-dropdown-group>
  </b-nav-item-dropdown>
</template>

<script>
import { mapState } from "vuex";
import router from "@/router";
import { bus } from "../main";
import store from "@/store";

export default {
  data() {
    return {};
  },
  methods: {
    switchAccount(string) {
      this.$log.debug("Switching account from dropdown");
      if (this.$store.state.accountid != string) {
        this.$log.debug("Checking SWITCH to " + string);
        for (var row of store.state.accountList) {
          if (row.account_id == string) {
            this.$log.debug("Found, SWITCH to " + string);
            this.$store.commit("selectedAccount", {
              id: row.account_id,
              name: row.name,
              level: row.user_level,
              gfwEnabled: row.global_forwarders_enabled,
              gwEnabled: row.gw_enabled
            });
            if (router.currentRoute.name != "root") {
              router.push({
                name: "root"
              });
            }
            break;
          }
        }
      } else {
        this.$log.debug("Not SWITCHING as current account");
      }
    }
  }
};
</script>

<style></style>
