import { helpers } from "@vuelidate/validators";

// validator to check a regex with a defined pattern
const NAMESPACE_REGEX = /^[a-zA-Z0-9*_-]*$/;

const NAMESPACE_REGEX_MESSAGE =
  "Namespace must contain only letters, numbers, *, _, or -";

const AWS_REGION_REGEX = /^(us|af|ap|ca|eu|me|sa)-(north|south|east|west|central|northeast|southeast)-\d+$/;

const AWS_REGION_REGEX_MESSAGE =
  "Region must be a valid AWS region code such as us-east-1";

const uniqueNamespace = installedNamespaces =>
  helpers.withMessage(
    "Namespace must be unique",
    value => !installedNamespaces.includes(value)
  );

const uniqueName = integrationNames =>
  helpers.withMessage(
    "Name must be unique",
    value => !integrationNames.includes(value)
  );

function checkValidRoutes(newRoutes, existingRoutes) {
  var existingFilters = existingRoutes.map(route => route.filter_events);
  var newFilters = newRoutes.map(route => route.filter_events);

  // if any of the new filter events are already in the existing routes, return false
  for (var i = 0; i < newFilters.length; i++) {
    if (existingFilters.includes(newFilters[i])) {
      return false;
    }
  }
  return true;
}

export {
  uniqueNamespace,
  checkValidRoutes,
  NAMESPACE_REGEX,
  NAMESPACE_REGEX_MESSAGE,
  AWS_REGION_REGEX,
  AWS_REGION_REGEX_MESSAGE
};
