<template>
  <div>
    <b-modal
      id="modal-filter-examples"
      title="Filter Pattern Examples"
      size="lg"
      hide-footer
    >
      <p><code>*</code> - All events</p>
      <p><code>device.*</code> - All requests and messages from devices</p>
      <p><code>device.message.*</code> - Asynchronous device messages only</p>
      <p><code>network.*</code> - Network generated events only</p>
      <p>
        <code>device.request.my_namespace.my_method</code> - Specific request
        namespace and methods
      </p>
      <p>
        For more information about events and event formats, see
        <a
          href="https://developer.blecon.net/learn-blecon/blecon-networks/blecon-events"
          target="docs"
          >Blecon Event Types</a
        >
      </p>
    </b-modal>

    <b-modal
      id="modal-edit"
      title="Edit Route"
      size="lg"
      hide-footer
      @hidden="onReset"
    >
      <b-form @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Filter pattern"
          label-for="input-1"
          description="Blecon Network filter pattern"
        >
          <b-form-input
            size="sm"
            id="input-2"
            v-model="form.filter_events"
            type="text"
          >
          </b-form-input>
          <div class="form-error" v-if="$v.value.form.filter_events.$error">
            Filter is required
          </div>
        </b-form-group>

        <b-form-group
          size="sm"
          id="input-group-1"
          label="Destination URL"
          label-for="input-1"
          description="JSON will be sent here as an HTTP POST"
        >
          <b-form-input
            size="sm"
            id="input-2"
            v-model.trim="$v.value.form.url.$model"
            type="text"
          >
          </b-form-input>
          <div class="form-error" v-if="!$v.value.form.url.required">
            URL is required
          </div>
          <div class="form-error" v-if="$v.value.form.url.$error">
            Valid URL required
          </div>
        </b-form-group>

        <b-container>
          <b-row class="my-1">
            <b-col sm="1">
              Key:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-key-1"
                size="sm"
                v-model="form.headers_key_1"
                placeholder="X-Header-Example"
              ></b-form-input>
            </b-col>
            <b-col sm="1">
              Value:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-val-1"
                v-model="form.headers_val_1"
                size="sm"
                placeholder="header-value"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="1">
              Key:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-key-2"
                v-model="form.headers_key_2"
                size="sm"
                placeholder="X-Header-Example"
              ></b-form-input>
            </b-col>
            <b-col sm="1">
              Value:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-value-2"
                v-model="form.headers_val_2"
                size="sm"
                placeholder="header-value"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="1">
              Key:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-key-3"
                v-model="form.headers_key_3"
                size="sm"
                placeholder="X-Header-Example"
              ></b-form-input>
            </b-col>
            <b-col sm="1">
              Value:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-val-3"
                v-model="form.headers_val_3"
                size="sm"
                placeholder="header-value"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-container>

        <div class="float-right mt-3">
          <b-button @click="cancelForm()" type="reset">Cancel</b-button>
          &nbsp;
          <b-button @click="editRouteSubmit()" variant="primary">Save</b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-add"
      title="Add Route"
      size="lg"
      hide-footer
      @hidden="onReset"
    >
      <b-form @reset="onReset">
        <b-form-group
          :class="{ 'form-group--error': $v.value.form.filter_events.$error }"
          size="sm"
          id="input-group-1"
          label="Filter pattern"
          number
          label-for="input-1"
          description="Blecon Network filter"
        >
          <b-form-input
            size="sm"
            id="input-1"
            v-model="$v.value.form.filter_events.$model"
            type="text"
            placeholder="Enter your filter pattern here"
            @input="validateFilterInput"
          >
          </b-form-input>
          <a
            target="docs"
            style="float: right"
            class=" small"
            v-b-modal.modal-filter-examples
            ><i class="bx bx-book-open"></i> View filter pattern examples</a
          >
          <div class="form-error" v-if="$v.value.form.filter_events.$error">
            Filter is required
          </div>
        </b-form-group>

        <b-form-group
          size="sm"
          id="input-group-1"
          label="URL"
          label-for="input-1"
          description="Destination URL"
        >
          <b-form-input
            size="sm"
            id="input-2"
            v-model.trim="$v.value.form.url.$model"
            type="text"
          >
          </b-form-input>
          <span style="float: right" class="text-muted small"
            ><i class="bx bx-book-open"></i> Your URL will receive JSON
            formatted data.
            <a
              href="https://developer.blecon.net/learn-blecon/blecon-networks/blecon-events"
              target="docs"
              >Learn more</a
            ></span
          >
          <div class="form-error" v-if="!$v.value.form.url.required">
            URL is required
          </div>
          <div class="form-error" v-if="$v.value.form.url.$error">
            Valid URL required
          </div>
        </b-form-group>
        <p>
          Headers
        </p>
        <b-container>
          <b-row class="my-1">
            <b-col sm="1">
              Key:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-key-1"
                size="sm"
                v-model="form.headers_key_1"
                placeholder="X-Header-Example"
              ></b-form-input>
            </b-col>
            <b-col sm="1">
              Value:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-val-1"
                v-model="form.headers_val_1"
                size="sm"
                placeholder="header-value"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="1">
              Key:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-key-2"
                v-model="form.headers_key_2"
                size="sm"
                placeholder="X-Header-Example"
              ></b-form-input>
            </b-col>
            <b-col sm="1">
              Value:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-value-2"
                v-model="form.headers_val_2"
                size="sm"
                placeholder="header-value"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="1">
              Key:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-key-3"
                v-model="form.headers_key_3"
                size="sm"
                placeholder="X-Header-Example"
              ></b-form-input>
            </b-col>
            <b-col sm="1">
              Value:
            </b-col>
            <b-col>
              <b-form-input
                id="headers-val-3"
                v-model="form.headers_val_3"
                size="sm"
                placeholder="header-value"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-container>

        <div class="float-right mt-3">
          <b-button type="reset" @click="cancelForm()">Cancel</b-button>
          &nbsp;
          <b-button @click="addRoute()" variant="primary">Add Route</b-button>
        </div>
      </b-form>
    </b-modal>

    <b-card class="mt-3" flush>
      <template #header>
        <h5 class="mb-0">Routes</h5>
      </template>

      <b-button
        class="float-right mb-5"
        variant="primary"
        size="sm"
        :title="$store.state.accountLevel > 20 ? 'Insufficent permissions' : ''"
        :disabled="$store.state.accountLevel > 20"
        v-b-modal.modal-add
        @click="loadData()"
        >Add route</b-button
      >
      <div v-if="!routes.length">
        <p>No routes defined yet.</p>
        <p>Add a route to forward your device events to a webhook handler.</p>
      </div>
      <b-table
        striped
        hover
        small
        :fields="fields"
        :items="routes"
        v-if="routes.length"
      >
        <template #cell(actions)="data">
          <div style="white-space: nowrap">
            <b-button
              class="mr-3"
              size="sm"
              :title="
                $store.state.accountLevel > 20 ? 'Insufficent permissions' : ''
              "
              :disabled="$store.state.accountLevel > 20"
              v-b-modal.modal-edit
              @click="editRoute(data.item.index)"
              variant="primary"
              >Edit</b-button
            >
            <b-button
              size="sm"
              :title="
                $store.state.accountLevel > 20 ? 'Insufficent permissions' : ''
              "
              :disabled="$store.state.accountLevel > 20"
              variant="danger"
              @click="deleteRoute(data.item)"
              >Delete</b-button
            >
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
//import shared from '@/components/Shared'
import router from "@/router";
import store from "@/store";

import { format, formatDistance, formatRelative, sub, subDays } from "date-fns";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers, url } from "@vuelidate/validators";
export default {
  setup() {
    return { $v: useVuelidate() };
  },
  data() {
    return {
      onCancel: false,
      fields: [
        { key: "filter_events", label: "Filter" },
        { key: "url", label: "Destination URL" },
        { key: "actions", label: "Actions" }
      ],
      routes: [],
      errors: [],
      isValid: true,
      form: this.getDefaultForm()
    };
  },
  validations: {
    form: {
      filter_events: {
        required
      },

      url: {
        required,
        url
      }
    }
  },
  created() {
    this.$v = useVuelidate(this.validations, this);
    this.loadData();
  },
  methods: {
    getDefaultForm() {
      return {
        filter_events: null,
        url: null,
        index: null,
        headers_key_1: "",
        headers_val_1: "",
        headers_key_2: "",
        headers_val_2: "",
        headers_key_3: "",
        headers_val_3: ""
      };
    },

    syncRoutes() {
      let submitted_routes = [];

      for (let i = 0; i < this.routes.length; i++) {
        // only add non empty header objects
        let to_submit = {};

        to_submit["filter_events"] = this.routes[i].filter_events;
        to_submit["url"] = this.routes[i].url;
        if (Object.keys(this.routes[i].headers).length > 0) {
          this.$log.debug(this.routes[i].headers);
          to_submit["headers"] = this.routes[i].headers;
        }

        submitted_routes.push(to_submit);
      }

      this.$axios
        .post(
          "/v1/accounts/" +
            store.state.accountid +
            "/networks/" +
            this.$route.params.networkId,
          {
            routes: submitted_routes
          }
        )
        .then(() => {
          //Reload from API
          this.$notify({
            title: "Routes updated",
            type: "success"
          });
          this.loadData();
        })
        .catch(err => {
          if (err.response != undefined) {
            this.$notify({
              title: "Error " + (err.response.data.message || ""),
              type: "error"
            });
            this.loadData();
          }
        });
    },
    cancelForm() {
      event.preventDefault();
      this.$root.$emit("bv::hide::modal", "modal-edit");
      this.$root.$emit("bv::hide::modal", "modal-add");
    },
    async editRouteSubmit() {
      this.$v.value.$touch();
      if (this.$v.value.$invalid) {
        return;
      }
      this.$root.$emit("bv::hide::modal", "modal-edit");
      event.preventDefault();
      var accessToken = await this.$auth.getTokenSilently();
      const keys = [
        ["headers_key_1", "headers_val_1"],
        ["headers_key_2", "headers_val_2"],
        ["headers_key_3", "headers_val_3"]
      ];

      let header_obj = {};
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        //only add if key and value is not empty
        if (this.form[key[0]]) {
          header_obj[this.form[key[0]]] = this.form[key[1]];
        }
      }

      //remove existing
      if (this.form.index !== -1) {
        this.routes.splice(this.form.index, 1);
      }
      //replace with new
      this.routes.unshift({
        filter_events: this.form.filter_events,
        url: this.form.url,
        headers: header_obj
      });
      this.syncRoutes();
    },
    async editRoute(index) {
      var item = this.routes[index];
      this.$v.value.$reset();

      const foundObject = this.routes.find(
        obj => obj["filter_events"] === item.filter_events
      );
      //build form data from api return
      this.form.filter_events = item.filter_events;
      this.form.url = item.url;
      this.form.index = index;

      Object.entries(foundObject.headers).forEach(([key, value], index) => {
        const keyVariable = `headers_key_${index + 1}`;
        const valueVariable = `headers_val_${index + 1}`;
        this.form[keyVariable] = key;
        this.form[valueVariable] = value;
      });
    },
    async deleteRoute(item) {
      var accessToken = await this.$auth.getTokenSilently();

      this.$confirm(
        "Are you sure you want to delete this route? Requests will fail on this filter until replaced."
      )
        .then(() => {
          var index = this.routes.indexOf(item);
          if (index !== -1) {
            this.routes.splice(index, 1);
          }
          this.syncRoutes();
        })
        .catch(() => {});
    },

    validateFilterInput() {
      //todo re match
      this.isValid = true;
    },

    async addRoute(event) {
      this.$v.value.$touch();
      if (this.$v.value.$invalid) {
        this.$log.debug(this.$v);
        this.isValid = false;
        return;
      }
      if (this.isValid) {
        this.$root.$emit("bv::hide::modal", "modal-add");
        var accessToken = await this.$auth.getTokenSilently();
        const keys = [
          ["headers_key_1", "headers_val_1"],
          ["headers_key_2", "headers_val_2"],
          ["headers_key_3", "headers_val_3"]
        ];

        let header_obj = {};
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (this.form[key[0]]) {
            header_obj[this.form[key[0]]] = this.form[key[1]];
          }
        }

        this.routes.unshift({
          filter_events: this.form.filter_events,
          url: this.form.url,
          headers: header_obj
        });

        this.syncRoutes();
      }
    },
    onReset(event) {
      this.$root.$emit("bv::hide::modal", "modal-add");
      this.$root.$emit("bv::hide::modal", "modal-edit");
      this.loadData();
      this.$v.value.$reset();
    },
    async loadData() {
      //reset form
      //spin
      this.$store.commit("spin");

      this.form = this.getDefaultForm();
      var accessToken = await this.$auth.getTokenSilently();
      this.$axios
        .get(
          "/v1/accounts/" +
            store.state.accountid +
            "/networks/" +
            this.$route.params.networkId
        )
        .then(response => {
          if (!response) return; // `response` is undefined

          this.routes = response.data.routes;
          for (let i = 0; i < this.routes.length; i++) {
            this.routes[i].index = i;
            try {
              this.routes[i].headers = this.routes[i].headers || {};
            } catch (e) {
              this.$log.debug(e);
            }
          }
        });
      this.$store.commit("nospin");
    }
  }
};
</script>
